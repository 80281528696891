.sponsors {
    position: relative;
}

.sponsors-background {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: #2F302D;
    z-index: -3;
}

.sponsors-grid {
    display: grid;
    grid-auto-rows: 20vw;
    grid-template-columns: repeat(5, 20%);
}

.sponsors-cell {
    display: flex;
    position: relative;

    justify-content: center;
}

.sponsors-cell-purple {
    background-color: #8151FB;
}

.sponsors-cell-white {
    background-color: #E1DBD2;
}

.sponsors-header {
    position: absolute;
    top: 38%;
    max-width: 97.5%;
}

.sponsors-image-container {
    margin: auto;
    text-align: center;
}

.sponsors-image {
    max-width: 96%;
    mix-blend-mode: multiply;
}

@media only screen and (max-width: 600px) {
    .sponsors-grid {
        grid-auto-rows: 33vw;
        grid-template-columns: repeat(3, 33.33%);
    }

    .sponsors-image {
        max-width: 75%;
    }
}
