.footer {
	position: relative;
}

.footer-background {
	position: absolute;
	width: 100%;
	height: 100%;

	background-color: #2F302D;
	z-index: -3;
}

.footer-container {
	position: relative;
	padding: 5% 8%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 60px;
}

.footer-top, .footer-bottom {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.footer-bottom {
	align-items: center;
}

.footer-link-sections {
	position: relative;
	width: 45%;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;

	gap: 50px;
}

.footer-links-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	flex: none;
}

.footer-links-header {
	height: 3.5vw;

	font-family: 'Magilio';
	font-style: normal;
	font-weight: 400;
	font-size: 3.2vw;
	line-height: 3.5vw;
	/* identical to box height */

	/* Beige */

	color: #E1DBD2;
	cursor: pointer;

	margin-bottom: 1.3vw;
}

.footer-link {
	height: 2.1vw;

	font-family: 'PP Object Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 1.6vw;
	line-height: 2.1vw;

	/* Beige */

	color: #E1DBD2;
	cursor: pointer;

	margin-bottom: 1.1vw;
}

.footer-links-header:hover, .footer-link:hover {
	text-decoration: none;
	color: #E1DBD2;
}

.footer-buttons-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	gap: 1.2vw;
}

.footer-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0.66vw;
	gap: 0.66vw;

	width: 18.4vw;
	height: 4.6vw;

	/* Beige */

	background: #E1DBD2;
	border-radius: 6.6vw;

	/* Black */

	color: #2F302D;

	transition: box-shadow 0.3s, color 0.3s, background-color 0.3s;
}

.footer-button:hover {
	text-decoration: none;
	box-shadow: 0px 0.5vw 0.5vw rgba(0, 0, 0, 0.25);
	color: #2F302D;
}

.footer-button:active {
	box-shadow: inset 0px 0.5vw 0.5vw rgba(0, 0, 0, 0.25);
	background-color: #2F302D;
	color: #E1DBD2;
}

.footer-button-text {
	height: 2.5vw;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 1.7vw;
	line-height: 2.5vw;
	/* identical to box height */

	letter-spacing: 0.03em;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
}

.footer-socials-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	gap: 15%;
}

.footer-logo {
	height: 5vw;
	width: 4.6vw;

/*	filter: blur(0.5px);*/
}

.footer-socials {
	width: 21.2vw;
	height: 5.3vw;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	flex: none;
}

.footer-social {
	flex: 1 0;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
}

.footer-social-image {
	width: 60%;
	height: 60%;
}

.footer-designathon-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.footer-designathon-top {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.footer-designathon-top-text {
	height: 1.8vw;

	font-family: 'Magilio';
	font-style: normal;
	font-weight: 400;
	font-size: 1.7vw;
	line-height: 1.8vw;

	/* Beige */

	color: #E1DBD2;
}

.footer-designathon-bottom-text {
	height: 3.6vw;

	font-family: 'Magilio';
	font-style: normal;
	font-weight: 400;
	font-size: 3.3vw;
	line-height: 3.6vw;
	/* identical to box height */

	/* Beige */

	color: #E1DBD2;
}

@media only screen and (max-width: 600px) {
    .footer-link-sections {
    	flex-direction: column;
    	gap: 20px;
    }

    .footer-links-header {
    	height: 9.0vw;
    	font-size: 8.2vw;
    	line-height: 9.0vw;

    	margin-bottom: 10px;
    }

    .footer-link {
    	height: 5.9vw;
    	font-size: 4.6vw;
    	line-height: 5.9vw;

    	margin-bottom: 10px;
    }

    .footer-button {
    	width: 36vw;
    	height: 9.2vw;
    }

    .footer-button-text {
    	height: 4.9vw;
    	font-size: 3.3vw;
    	line-height: 4.9vw;
    }

    .footer-bottom {
    	flex-direction: column-reverse;
    	gap: 30px;
    }

    .footer-socials-container {
    	width: 100%;
    	justify-content: space-evenly;
    }

    .footer-logo {
    	width: 11.5vw;
    	height: 12vw;
    }

    .footer-socials {
    	width: 53vw;
    	height: 13vw;
    }

    .footer-designathon-top-text {
    	height: 5.6vw;
    	font-size: 5.1vw;
    	line-height: 5.6vw;
    }

    .footer-designathon-bottom-text {
    	height: 11vw;
    	font-size: 10vw;
    	line-height: 11vw;
    }
}