.prompt {
	padding: 4vw 8%;
	
	position: relative;
	
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	
	gap: 40px;
	
	margin-bottom: 10vw;
}

.prompt-box {
	width: max(35vw, 450px);
	height: max(25vw, 340px);
	
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	text-align: center;
	
	border-radius: 20px;
	padding: 20px;
}

.prompt-box-purple {
	background: #8151FB;
}

.prompt-box-orange {
	background: #F55E2F;
}

.prompt-box-blue {
	background: #1173D9;
}

.prompt-box-yellow {
	background: #FF9A1A;
}

.prompt-header {
	color: #F7F3ED;
	
	leading-trim: both;
	
	text-edge: cap;
	-webkit-text-stroke-width: 5.5;
	-webkit-text-stroke-color: #8151FB;
	font-family: Magilio;
	font-size: max(50px, 4.4vw);
	font-style: normal;
	font-weight: 400;
	line-height: max(50px, 4.4vw);
}

.prompt-description {
	color: #F7F3ED;
	
	text-align: center;
	font-family: "PP Object Sans";
	font-size: max(19px, 1.6vw);
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.prompt-button {
	display: flex;
	width: max(230.215px, 20vw);
	height: max(43px, 3.8vw);
	padding: max(10px, 1.8vw);
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	
	color: #F7F3ED;
	
	font-family: Poppins;
	font-size: max(20px, 1.7vw);
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.6px;
	
	border-radius: 100px;
	border: 1px solid #F7F3ED;
}

.prompt-button:hover {
	color: #F7F3ED;
}