.prizes {
    position: relative;
}

.prizes-background {
    position: absolute;
    width: 100%;
    height: 100%;

    z-index: -3;
}

.prizes-frame {
    margin: auto;
    padding: 4rem 0;
    width: 84%;
}

.prizes-frame h2 {
    -webkit-text-stroke: 3px #E5889D;
    color: rgb(0,0,0,0);
    font: 4rem/normal Magilio;
}

.prizes-beginner, .prizes-intermediate {
    display: flex;
    flex: 1;
    gap: 6rem;
    margin: 4rem 0;
}

.prizes-beginner > div, .prizes-intermediate > div {
    flex: 1;
}

.prizes-beginner h4, .prizes-intermediate h4 {
    font-family: "PP Object Sans";
    font-weight: bold;
}

.prizes-beginner div:first-of-type > h4, .prizes-intermediate div:first-of-type > h4 {
    color: #FAC640;
}

.prizes-beginner div:nth-of-type(2) > h4, .prizes-intermediate div:nth-of-type(2) > h4 {
    color: #57CFBA;
}

.prizes-beginner div:last-of-type > h4, .prizes-intermediate div:last-of-type > h4 {
    color: #FFA8A6;
}

@media (max-width: 992px) {
    .prizes-beginner, .prizes-intermediate {
        display: unset;
    }

    .prizes-beginner > div, .prizes-intermediate > div {
        margin: 4em 0;
    }
}
