html,
body {
	/* Beige */
	color: #E1DBD2;

	scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'sans-serif';
}

h1 {
  font-family: 'Magilio';
}

h2 {
  font-family: 'PP Object Sans';
}