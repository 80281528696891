.FAQs {
    position: relative;
    padding: 4vw 0;
}

.FAQs-background {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: #2F302D;
    z-index: -3;
}

.FAQs-frame {
    padding: 0 8%;
}

.FAQs-header {
    height: 100px;
    position: relative;
}

.FAQs-header > img {
    height: 110px;
    position: absolute;
    width: 100%;
}

.FAQs-question {
    letter-spacing: 0.03em;
    line-height: 2.25em;
    font-size: 2.25em;
    font-weight: 800;
    margin-top: 4%;
}

.FAQs-question > b {
    color: #B8E1D1;
}

.FAQs-answer {
    font: normal 1.5em/1.5em 'PP Object Sans';
    letter-spacing: 0.03em;
    width: 88%;
}

@media only screen and (max-width: 600px) {
    .FAQs-header {
        height: 69px;
    }
    
    .FAQs-question {
        letter-spacing: 0.03em;
        line-height: 1.2em;
        font-size: 1.2em;
        font-weight: 800;
        margin-top: 10%;
    }

    .FAQs-answer {
        font: normal 0.8em 'PP Object Sans';
        letter-spacing: 0.03em;
        width: 100%;
    }
}
