:root {
    --speakers-teal: #46C2C0;
    --speakers-blue: #1173D9;
    --speakers-pink: #EDB2D0;
    --speakers-purple: #8151FB;
}

.speakers-background {
	position: absolute;
	width: 100%;
	height: 100%;

	background-color: #2F302D;
	z-index: -3;
}

.speakers-frame {
    padding: 4vw 8%;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
}

.speakers-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

.speakers-header {
    color: #2F302D;
    font-family: Magilio;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.speakers-header-teal {
    text-stroke: 3px var(--speakers-teal);
    -webkit-text-stroke: 3px var(--speakers-teal);
}

.speakers-header-blue {
    text-stroke: 3px var(--speakers-blue);
    -webkit-text-stroke: 3px var(--speakers-blue);
}

.speakers-header-purple {
    text-stroke: 3px var(--speakers-purple);
    -webkit-text-stroke: 3px var(--speakers-purple);
}

.speakers-row {
    display: flex;
    align-items: flex-start;
    gap: max(40px, 2vw);
    
    flex-wrap: wrap;
}

.speakers-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    cursor: pointer;
}

.speakers-profile {
    width: max(208px, 16vw);
    height: max(208px, 16vw);
    
    border-radius: 1000px;
    
    margin-bottom: -28px;
}

.speakers-profile-teal {
    border: 2px solid var(--speakers-teal);
}

.speakers-profile-blue {
    border: 2px solid var(--speakers-blue);
}

.speakers-profile-purple {
    border: 2px solid var(--speakers-purple);
}

.speakers-desc {
    display: flex;
    flex-direction: column;
    width: max(239.2px, 18vw);
    /* height: 80.376px; */
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    /* gap: 6px; */
    flex-shrink: 0;
    
    text-align: center;
}

.speakers-desc-teal {
    background: var(--speakers-teal);
}

.speakers-desc-blue {
    background: var(--speakers-blue);
}

.speakers-desc-purple {
    background: var(--speakers-purple);
}

.speakers-name {
    text-align: center;
    font-family: "PP Object Sans";
    font-size: max(16.8px, 1.3vw);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.504px;
}

.speakers-info {
    font-family: "PP Object Sans";
    font-size: max(12px, 0.92vw);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
}

.speakers-workshop {
    font-family: "PP Object Sans";
    font-size: max(12px, 0.92vw);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.36px;
}

.speakers-text-dark {
    color: #2F302D;
}

.speakers-text-light {
    color: #D9D9D9;
}

.speakers-tooltip-frame {
    background: url('../../images/s24_images/postit.svg');
    width: 543px;
    height: 507px;
    
    z-index: 2;
    
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    padding-top: 45px;
    padding-left: 45px;
    padding-right: 36px;
    padding-bottom: 36px;
}

.speakers-tooltip-header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    
    align-items: center;
}

.speakers-tooltip-profile {
    width: 140px;
    height: 140px;
    flex-shrink: 0;
    
    border-radius: 11.732px;
    border: 2px solid #1173D9;
}

.speakers-tooltip-name {
    color: #1173D9;
    font-family: "PP Object Sans";
    font-size: 26.525px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.796px;
}

.speakers-tooltip-info {
    color: #1173D9;
    font-family: "PP Object Sans";
    font-size: 18.946px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.568px;
}

.speakers-tooltip-description {
    color: #2F302D;
    font-family: "PP Object Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.48px;
}

@media only screen and (max-width: 600px) {
    .speakers-frame {
        padding: 2.4em 8%;
    }
    
    .speakers-section {
        gap: 20px;
    }

    .speakers-header {
        height: 16vw;
        position: relative;
        font-size: 12vw;
    }
    
    .speakers-header-teal {
        text-stroke: 0.5vw var(--speakers-teal);
        -webkit-text-stroke: 0.5vw var(--speakers-teal);
    }
    
    .speakers-header-blue {
        text-stroke: 0.5vw var(--speakers-blue);
        -webkit-text-stroke: 0.5vw var(--speakers-blue);
    }
    
    .speakers-header-pink {
        text-stroke: 0.5vw var(--speakers-pink);
        -webkit-text-stroke: 0.5vw var(--speakers-pink);
    }
    
    .speakers-row {
        justify-content: center;
        width: 100%;
        gap: 30px;
    }
    
    .speakers-profile {
        width: 35vw;
        height: 35vw;
    }
    
    .speakers-desc {
        width: 37vw;
    }

    .speakers-name {
        font: 800 12.8px/16.64px 'PP Object Sans';
        margin: 0;
    }

    .speakers-position {
        font: 9.6px/12.48px 'PP Object Sans';
        margin: 0;
    }
}